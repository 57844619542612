exports.components = {
  "component---src-pages-sitemonitor-js": () => import("./../../../src/pages/sitemonitor.js" /* webpackChunkName: "component---src-pages-sitemonitor-js" */),
  "component---src-pm-framework-templates-404-js": () => import("./../../../src/pm_framework/templates/404.js" /* webpackChunkName: "component---src-pm-framework-templates-404-js" */),
  "component---src-pm-framework-templates-article-js": () => import("./../../../src/pm_framework/templates/article.js" /* webpackChunkName: "component---src-pm-framework-templates-article-js" */),
  "component---src-pm-framework-templates-articlelist-js": () => import("./../../../src/pm_framework/templates/articlelist.js" /* webpackChunkName: "component---src-pm-framework-templates-articlelist-js" */),
  "component---src-pm-framework-templates-bookdetail-js": () => import("./../../../src/pm_framework/templates/bookdetail.js" /* webpackChunkName: "component---src-pm-framework-templates-bookdetail-js" */),
  "component---src-pm-framework-templates-contributor-js": () => import("./../../../src/pm_framework/templates/contributor.js" /* webpackChunkName: "component---src-pm-framework-templates-contributor-js" */),
  "component---src-pm-framework-templates-edition-js": () => import("./../../../src/pm_framework/templates/edition.js" /* webpackChunkName: "component---src-pm-framework-templates-edition-js" */),
  "component---src-pm-framework-templates-event-list-js": () => import("./../../../src/pm_framework/templates/event_list.js" /* webpackChunkName: "component---src-pm-framework-templates-event-list-js" */),
  "component---src-pm-framework-templates-general-js": () => import("./../../../src/pm_framework/templates/general.js" /* webpackChunkName: "component---src-pm-framework-templates-general-js" */),
  "component---src-pm-framework-templates-general-wide-js": () => import("./../../../src/pm_framework/templates/general_wide.js" /* webpackChunkName: "component---src-pm-framework-templates-general-wide-js" */),
  "component---src-pm-framework-templates-genre-js": () => import("./../../../src/pm_framework/templates/genre.js" /* webpackChunkName: "component---src-pm-framework-templates-genre-js" */),
  "component---src-pm-framework-templates-home-js": () => import("./../../../src/pm_framework/templates/home.js" /* webpackChunkName: "component---src-pm-framework-templates-home-js" */),
  "component---src-pm-framework-templates-resources-js": () => import("./../../../src/pm_framework/templates/resources.js" /* webpackChunkName: "component---src-pm-framework-templates-resources-js" */)
}

